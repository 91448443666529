body {
    font-family: oswald, serif;
    font-size: large;
    background-color: rgb(216, 192, 158);
}

.nav-link {
    color: #fff!important;
    font-size: 25px!important;
    padding: 0 10px 0 10px;
    margin: 0 10px 0 10px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.sigCanvas {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.bg-cart {
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    color: #fff!important;
    background-color: #dc3545;
    background-image: linear-gradient(45deg, black 25%, transparent 25.5%, transparent 50%, black 50.5%, black 75%, transparent 75.5%, transparent);
    background-size:  50px 50px;
}

.bg-pending {
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    color: #fff!important;
    background-color: #ffc107;
    background-image: linear-gradient(45deg, black 25%, transparent 25.5%, transparent 50%, black 50.5%, black 75%, transparent 75.5%, transparent);
    background-size:  50px 50px;
}

.logoSpin {
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

.react-date-picker__wrapper {
    border: none!important;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@media screen {
    .onlyprint { display: none; }
}

@media print {
    #RoomChargeReport nav { display: none; }
    .noprint { display: none; }
}

.location {
    overflow: auto;
}

.units {
    font-size: larger;
    display: block;
    height: 178px;
}
.oswald {  }
.form-inline .form-group { display: block; }

.sigCanvas {
    border: 2px dashed #ccc;
    border-radius: 10px;
    max-width: 100%;
}

h1, h2 ,h3 ,h4, h5, .btn {
    text-transform: uppercase;

}

.input-group>.custom-select:not(:first-child), .input-group>.form-control:not(:first-child) {
    height: 60px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: rgba(13,110,253,1);
    color: #fff;
}
.nav-tabs .nav-link {
    background-color: #6c757d;
    color: #fff;
    border: 0px solid black;
}
.nav-tabs .nav-link.active {
    border: 0px solid black;
}
.nav-tabs .nav-link.disabled {
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    border-bottom: 0 solid black;
    color: #fff;
}

.nav-tabs {
    border-bottom: 1px solid transparent;
}

.bg-success.inCart {
    background: repeating-linear-gradient(
        45deg,
        #28a746,
        #28a746 10px,
        #6c757d 10px,
        #6c757d 20px
    );
}

.bg-danger.inCart {
    background: repeating-linear-gradient(
        45deg,
        #dc3545,
        #dc3545 10px,
        #6c757d 10px,
        #6c757d 20px
    );
}

.bg-warning.inCart {
    background: repeating-linear-gradient(
        45deg,
        #ffc106,
        #ffc106 10px,
        #6c757d 10px,
        #6c757d 20px
    );
}

.bg-primary.inCart {
    background: repeating-linear-gradient(
        45deg,
        #057bff,
        #057bff 10px,
        #6c757d 10px,
        #6c757d 20px
    );
}

.bg-dark.inCart {
    background: repeating-linear-gradient(
        45deg,
        #353a40,
        #353a40 10px,
        #6c757d 10px,
        #6c757d 20px
    );
}


.bg-dark.moveUnit, .bg-primary.moveUnit, .bg-warning.moveUnit, .bg-danger.moveUnit, .bg-success.moveUnit {
    color: #000;
    background: repeating-linear-gradient(
        45deg,
        #007bff,
        #007bff 15px,
        #fff 0px,
        #fff 20px
    );
}

.btn-occupied, .bg-occupied, .btn-occupied-flashing {
    color: #fff;
    background-color: #9D7BD8;
}

.border-occupied {
    border-color: #9D7BD8!important;
}

.btn-move, .bg-move {
    color: #000;
    background: repeating-linear-gradient(
        45deg,
        #007bff,
        #007bff 15px,
        #fff 0px,
        #fff 20px
    );
}

.flashingTitle {
    animation: blinkingText 2s infinite;
}

.bg-occupied-flashing {
    color: #fff;
    animation: blinkingBackground 2s infinite;
}
@keyframes blinkingBackground{
    0%		{ background-color: #10c018;}
    25%		{ background-color: #1056c0;}
    50%		{ background-color: #ef0a1a;}
    75%		{ background-color: #254878;}
    100%	{ background-color: #04a1d5;}
}
@keyframes blinkingText{
    0%		{ color: #10c018;}
    25%		{ color: #1056c0;}
    50%		{ color: #ef0a1a;}
    75%		{ color: #254878;}
    100%	{ color: #04a1d5;}
}
