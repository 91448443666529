/* -------------------------------------
    GLOBAL
    A very basic CSS reset
------------------------------------- */
#Receipt img {
    max-width: 100%;
}

/* -------------------------------------
    BODY & CONTAINER
------------------------------------- */

.body-wrap {
    background-color: #f6f6f6;
    width: 100%;
}

#Receipt .container {
    display: block !important;
    max-width: 600px !important;
    margin: 0 auto !important;
    /* makes it centered */
    clear: both !important;
}

#Receipt .content {
    max-width: 600px;
    margin: 0 auto;
    display: block;
    padding: 20px;
}

/* -------------------------------------
    HEADER, FOOTER, MAIN
------------------------------------- */
#Receipt .main {
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
}

#Receipt .content-wrap {
    padding: 20px;
}

#Receipt .content-block {
    padding: 0 0 20px;
}

/* -------------------------------------
    INVOICE
    Styles for the billing table
------------------------------------- */
.invoice {
    margin: 10px auto;
    text-align: left;
    width: 80%;
}
.invoice td {
    padding: 5px 0;
}
.invoice .invoice-items {
    width: 100%;
}
.invoice .invoice-items td {
    border-top: #eee 1px solid;
}
.invoice .invoice-items .total td {
    border-top: 2px solid #333;
    border-bottom: 2px solid #333;
    font-weight: 700;
}

